

.data-handler{
    display: flex;
    text-align: center;
}

.data-handler p{
    margin: 0;
    padding: 3px 10px;
    font-weight: 600;
}

.data-handler > div{
    border: 1px solid #4db78e;
    margin: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.data-handler > div:hover{
    background-color: #4db78e;
    color: white;
}

.infobar{
    display: flex;
    align-items: flex-end;
    background-color: white;
    justify-content: space-between;
}

.otherinfo{
    display: flex;
    align-items: baseline;
}

.otherinfo > div{
    padding: 20px 5px;
    border-bottom: 3px solid #4db78e;
    margin: 0 10px;
}

.otherinfo  select{
    border:none;
    background-color: white;
    font-size: 17px;
}
.otherinfo select:focus{
    outline: none;
}
.nametime{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: white;
    margin: 10px 0;
    border-radius: 5px;
    font-weight: 600;
}

.timeHandle{
    display: flex;
    justify-content: space-evenly;
}

.time{
    padding: 5px 30px;
    cursor: pointer;
    border: 1px solid #236ebe80;
    margin: 0 20px;
    color: #236ebe;
}

.time-inactive{
    padding: 5px 30px;
    border: 1px solid #adb0b4c2;
    margin: 0 20px;
    color: #8e9092ca;
}

.time:hover{
    background-color: #236ebe;
    color: white
}

.active{
    background: #4db78e; 
    color: white;
}

.inactive{
    color: #4db78e;
}

.theatre{
}