@import url(https://fonts.googleapis.com/css2?family=Cookie&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* //test change for arjun*/


.App{
    
}


.Booking_data-handler__1jv8E{
    display: flex;
    text-align: center;
}

.Booking_data-handler__1jv8E p{
    margin: 0;
    padding: 3px 10px;
    font-weight: 600;
}

.Booking_data-handler__1jv8E > div{
    border: 1px solid #4db78e;
    margin: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.Booking_data-handler__1jv8E > div:hover{
    background-color: #4db78e;
    color: white;
}

.Booking_infobar__2rgAa{
    display: flex;
    align-items: flex-end;
    background-color: white;
    justify-content: space-between;
}

.Booking_otherinfo__7026B{
    display: flex;
    align-items: baseline;
}

.Booking_otherinfo__7026B > div{
    padding: 20px 5px;
    border-bottom: 3px solid #4db78e;
    margin: 0 10px;
}

.Booking_otherinfo__7026B  select{
    border:none;
    background-color: white;
    font-size: 17px;
}
.Booking_otherinfo__7026B select:focus{
    outline: none;
}
.Booking_nametime__3M8Dx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: white;
    margin: 10px 0;
    border-radius: 5px;
    font-weight: 600;
}

.Booking_timeHandle__2pklj{
    display: flex;
    justify-content: space-evenly;
}

.Booking_time__157ir{
    padding: 5px 30px;
    cursor: pointer;
    border: 1px solid #236ebe80;
    margin: 0 20px;
    color: #236ebe;
}

.Booking_time-inactive__1Kcdr{
    padding: 5px 30px;
    border: 1px solid #adb0b4c2;
    margin: 0 20px;
    color: #8e9092ca;
}

.Booking_time__157ir:hover{
    background-color: #236ebe;
    color: white
}

.Booking_active__3KE0Z{
    background: #4db78e; 
    color: white;
}

.Booking_inactive__3y31d{
    color: #4db78e;
}

.Booking_theatre__34tRZ{
}
